
import Vue from 'vue'
import Component from 'vue-class-component'
// import Axios from 'axios'

@Component({
  name: 'AccountSettings'
})

export default class AccountSettings extends Vue {

}
